export default {
    nav: {
        subject: "聚会点名"
    },
    field: {
        attend: {
            subject: "请选择聚会"
        }
    },
    empty: {
        subject: "此聚会不开放签到",
        text: "重新读取"
    },
    edit: {
        nav: {
            subject: "点名内容"
        },
        notice: {
            subject: "聚会需要在开始之后才可以进行点名",
            end: {
                subject: "本场聚会已经结束"
            }
        },
        register: {
            subject: "聚会报到",
            feature: {
                register: "领袖签到",
                qrcode: "显示二维码",
                scan: "扫描二维码",
                url: "复制报到网址",
                detail: "聚会详情",
                rebuild: "重制点名单"
            }
        },
        field: {
            begin: {
                subject: "开始时间"
            },
            end: {
                subject: "结束时间"
            },
            location: {
                subject: "地点"
            },
            note: {
                subject: "备注"
            },
            detail: {
                subject: "详细资讯",
                rollcall: {
                    subject: "点名单",
                    seq: {
                        subject: "序号"
                    },
                    uuid: {
                        subject: "识别码"
                    },
                },
                team: {
                    subject: "族群",
                    seq: {
                        subject: "序号"
                    },
                    uuid: {
                        subject: "识别码"
                    },
                    name: {
                        subject: "名称"
                    }
                },
                attend: {
                    subject: "聚会",
                    seq: {
                        subject: "序号"
                    },
                    uuid: {
                        subject: "识别码"
                    }
                },
                cycle: {
                    subject: "循环",
                    seq: {
                        subject: "序号"
                    }
                }
            }
        },
        text: {
            available: {
                subject: "应到会友"
            },
            multiple: {
                subject: "可复选"
            }
        },
        read: {
            subject: "发布时间 %s"
        },
        submit: {
            submit: "递交点名",
            all: {
                on: "全数出席",
                off: "取消全数"
            },
            not: {
                this: {
                    week: "本周无聚会"
                }
            }
        },
        success: {
            submit: {
                subject: "递交成功"
            },
            register: {
                subject: "递交成功"
            },
            rebuild: {
                subject: "重制成功"
            }
        },
        failure: {
            submit: {
                subject: "递交失败"
            },
            register: {
                subject: "递交失败"
            },
            rebuild: {
                subject: "重制失败"
            }
        },
        handler: {
            register: {
                subject: "领袖签到",
                text: "即将为您递交签到, 请确认后执行"
            },
            qrcode: {
                subject: "显示二维码",
                text: "请出示本次聚会二维码\n给会友扫描进行报到"
            },
            scan: {
                subject: "扫描二维码",
                text: "请您进行扫描\n会友二维码进行报到"
            },
            rebuild: {
                subject: "重制点名单",
                text: "若您发现点名单成员不正确\n可进行点名单重制, 请确认后执行",
                ended: {
                    subject: "重制点名单",
                    text: "本场聚会已经结束, 无法重制点名单"
                }
            },
            submit: {
                subject: "即将进行递交点名",
                text: "请确认后执行"
            }
        }
    }
}
